import React from 'react';
import ReactDOM from 'react-dom/client';
import Borg from './Borg';
import './style.css';
import { inject } from '@vercel/analytics';

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);

  // Add timestamps to favicon URLs
  const faviconLinks = document.querySelectorAll('link[rel="icon"], link[rel="apple-touch-icon"]');
  faviconLinks.forEach((link) => {
    const href = link.getAttribute('href');
    const timestamp = new Date().getTime();
    const updatedHref = `${href}?t=${timestamp}`;
    link.setAttribute('href', updatedHref);
  });

  root.render(
    <React.StrictMode>
      <Borg />
    </React.StrictMode>
  );
}

inject();
