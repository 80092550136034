import React from "react";

function OpeningDescription({ onClose }) {
  return (
    <div className="opening-description">
      <div className="description-content">
        <button className="close-button" onClick={onClose}>
          X
        </button>
        <h2>Welcome to LeBorg James!</h2>
        <p>This website is all about...</p>
        {/* Add your description content here */}
      </div>
    </div>
  );
}

export default OpeningDescription;
