import React, { useState,useEffect } from "react";
import DrinkSelector from "./DrinkSelector";
import Tally from "./Tally";
import "./style.css";
import OpeningDescription from "./OpeningDescription";
import Confetti from "react-confetti";
import ConfettiExplosion from 'react-confetti-explosion';
import QRCode from "qrcode.react";


export const drinks = [
  // { name: "pick your poison", shotAmount: 0.00000001 },
  { name: "shot of hard alc", shotAmount: 1 },
  { name: "seltzer", shotAmount: 1 },
  { name: "can of beer", shotAmount: 1 },
  { name: "glass of wine", shotAmount: 1 },
  { name: "handle of hard alc", shotAmount: 40 },
  { name: "fifth of hard alc", shotAmount: 16 },
  { name: "bottle of soju (13%)", shotAmount: 3 },
  { name: "shot of soju", shotAmount: 0.5 },
  { name: "half pint of hard alc", shotAmount: 4 },
  { name: "buzzball", shotAmount: 1.5 },
  { name: "beatbox", shotAmount: 3 },
  { name: "long island iced tea", shotAmount: 2 },
  { name: "AMF", shotAmount: 2 },
  { name: "bottle of wine", shotAmount: 5 },
  { name: "red solo cup of jungle juice", shotAmount: 3 },
  { name: "swig", shotAmount: 0.5 },
  { name: "borg", shotAmount: 17 },
];


function Borg() {
  const [tally, setTally] = useState([]);
  const [showTotal, setShowTotal] = useState(false);
  const [showOpeningDescription, setShowOpeningDescription] = useState(true);
  const [totalShots, setTotalShots] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    setTotalShots(calculateTotalShots());
  }, [tally]);

  useEffect(() => {
    // Retrieve stored tally from local storage on component mount
    const storedTally = localStorage.getItem("borgTally");
    if (storedTally) {
      setTally(JSON.parse(storedTally));
    }
  }, []);

  useEffect(() => {
    // Store tally in local storage whenever it changes
    localStorage.setItem("borgTally", JSON.stringify(tally));
  }, [tally]);
  
  function addDrink(drink, fraction) {
    let shotAmount = roundToTenth(drink.shotAmount * fraction);
    setTally((prevTally) => [...prevTally, { drink: drink.name, shotAmount }]);
  }

  function removeDrink(index) {
    setTally((prevTally) => prevTally.filter((_, i) => i !== index));
  }

  function roundToTenth(num) {
    return Math.round(num * 10) / 10;
  }

  function calculateTotalShots() {
    let totalShots = 0;
    for (const item of tally) {
      totalShots += item.shotAmount;
    }
    return roundToTenth(totalShots);
  }


  function handleShowTotal(totalShots) {
    setShowTotal(true);
    setShowConfetti(totalShots >= 50);
  }

  function handleCloseOpeningDescription() {
    setShowOpeningDescription(false);
  }


  function shareBorg(totalShots) {
    const shareUrl = "https://leborgjames.com";
    const shareTitle = "Borg";
    const shareText = `I had ${totalShots} shots! See if you can top me! Try Borg with the link above! `;
    try {
      if (navigator.share) {
        navigator
          .share({ title: shareTitle,url: shareUrl, text: shareText})
          .then(() => console.log("Shared successfully"))
          .catch((error) => console.error("Error sharing:", error));
      } else {
        console.log("Web Share API is not supported");
      }
    } catch (error) {
      console.error("Error sharing:", error);
    }
  }
  
  useEffect(() => {
    const handleShareButtonClick = () => {
      shareBorg();
    };
  
    // Clean up the event listener on component unmount
    return () => {
      // Remove the event listener
      document
        .getElementById("shareButton")
        .removeEventListener("click", handleShareButtonClick);
    };
  }, []);

  function toggleQRCode() {
    setShowQRCode((prevState) => !prevState);
  }

  function handleReset() {
    setTally([]);
    localStorage.removeItem("tally");
  }
  
  

  return (
    <div>
      <div className="confetti-container">
        {showConfetti && (
          <ConfettiExplosion
            particleCount={350}
            particleSize={15}
            colors={['#00ff00', '#000000', '#ff00ff']} // Neon green, black, and magenta colors
            width={1600}
            force={0.7}
            duration={5000}
          />
        )}
      </div>
      {showOpeningDescription && (
        <div className="opening-description">
          <h2>Welcome To Borg</h2>
          <p>The only product out there that promotes <s>healthy</s> drinking habits</p>
          <p>Keep a running tally as you start your night and reveal your borg at the end</p>
          <p>Or, use it in the morning to see what went wrong (if you even remember how much you drank)</p>
          <span className="close-button" onClick={handleCloseOpeningDescription}>Start!</span>
        </div>
      )}
      <h1 id="borg-title">Borg</h1>
      <div id="drink-selector-container">
        <DrinkSelector drinks={drinks} addDrink={addDrink} />
      </div>

      {tally.length > 0 && (
        <div id="tally-container">
          {!showTotal && (
            <>
              <button onClick={() => handleShowTotal(totalShots)} className="button">
                Show Me My Borg
              </button>
              <button className = "reset-button" onClick={handleReset}>
                Reset
              </button>
              <Tally tally={tally} removeDrink={removeDrink} />
            </>
          )}
          {showTotal && (
            <div id="total-shots-container">
              <div id="total-shots">
                Total Shots: {calculateTotalShots()}
                <div id="prompt-text">
                  {calculateTotalShots() >= 50 && "you only live once (*lived)"}
                  {calculateTotalShots() >= 35 && calculateTotalShots() < 50 && "Congrats! You unlocked a secret easter egg! It's called alcohol poisoning!"}
                  {calculateTotalShots() >= 25 && calculateTotalShots() < 35 && "how are you alive?"}
                  {calculateTotalShots() >= 20 && calculateTotalShots() < 25 && "you are a fucking degen"}
                  {calculateTotalShots() >= 15 && calculateTotalShots() < 20 && "what the fuck happened last night?"}
                  {calculateTotalShots() >= 10 && calculateTotalShots() < 15 && "your liver hates you"}
                  {calculateTotalShots() >= 5 && calculateTotalShots() < 10 && "your keys call to you"}
                  {calculateTotalShots() > 2 && calculateTotalShots() < 5 && "ok, mr. responsible"}
                  {calculateTotalShots() <= 2 && "no bitches?"}
                </div>
              </div>
              <Tally tally={tally} removeDrink={removeDrink} />
            </div>
          )}
        </div>
      )}
      <div id="footer">
        <span id="author"></span>
      </div>
    <button id="shareButton" onClick={() => shareBorg(totalShots)}>
      Share Your Borg!
    </button>
    <button className="qr-code-button" onClick={toggleQRCode}>
        Share QR Code!
      </button>
      {showQRCode && (
        <div className="qr-code-container" onClick={toggleQRCode}>
          <QRCode size={350} value="https://leborgjames.com" />
        </div>
      )}

  </div>
);
  
}

export default Borg;
