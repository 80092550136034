import React from "react";

function Tally({ tally, removeDrink }) {
  const totalShots = tally.reduce((acc, curr) => acc + curr.shotAmount, 0);

  return (
    <div id="tally-container">
      {/* <h2 id="tally-title">Tally</h2> */}
      <ul className="tally-list">
        {tally.map((drink, index) => (
          <li className="tally-item" key={index}>
            <span className="tally-item-name">{drink.drink}</span> - {drink.shotAmount} shots
            <button className="button" onClick={() => removeDrink(index)}>Remove</button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Tally;
