import React, { useState } from "react";
import './style.css'; 

function toFraction(decimal) {
  let numerator = 1;
  let denominator = 1;

  // Convert decimal to fraction
  while (decimal !== 0) {
    if (Math.abs(numerator / denominator - decimal) > Number.EPSILON) {
      if (numerator / denominator < decimal) {
        numerator += 1;
      } else {
        denominator += 1;
        numerator = Math.round(decimal * denominator);
      }
    } else {
      break;
    }
  }

  // Simplify fraction
  for (let i = numerator; i > 1; i--) {
    if (numerator % i === 0 && denominator % i === 0) {
      numerator /= i;
      denominator /= i;
      break;
    }
  }

  return `${numerator}/${denominator}`;
}

function DrinkSelector({ drinks, addDrink }) {
  const [selectedDrink, setSelectedDrink] = useState(drinks[0]);
  const [selectedFraction, setSelectedFraction] = useState(1);

  function handleDrinkChange(event) {
    const drink = drinks.find((d) => d.name === event.target.value);
    setSelectedDrink(drink);
  }

  function handleFractionChange(event) {
    setSelectedFraction(Number(event.target.value));
  }

  function handleAddClick() {
    addDrink(selectedDrink, selectedFraction);
  }

  return (
    <div id="drink-selector-container">
      <select id="drink-selector" value={selectedDrink.name} onChange={handleDrinkChange}>
        {drinks.map((drink) => (
          <option key={drink.name} value={drink.name}>
            {drink.name}
          </option>
        ))}
      </select>
      <select id="fraction-selector" value={selectedFraction} onChange={handleFractionChange}>
        {[1 / 4, 1 / 3, 1 / 2, 2 / 3, 3 / 4, 1].map((fraction) => (
          <option key={fraction} value={fraction}>
            {toFraction(fraction)}
          </option>
        ))}
      </select>
      <button id="add-button" onClick={handleAddClick}>
        Add
      </button>
    </div>
  );
  
  
}

export default DrinkSelector;
